body {
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    /*This is to block text selection for user*/
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    /*This is to support \n as newline in text*/
    white-space: pre-line;

    /*This is block scroll down to refresh*/
    overscroll-behavior-y: none;
}

input, textarea {
    -moz-user-select: text;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.container {
    position: relative;
}

.footer {
    z-index: 1;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
}

.scrollable {
    overflow-y: scroll;
    overflow-x: hidden;
    position: relative;
}

.appModal {
    z-index: 20;
    top: 0;
    left: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    position: fixed;
}

.opacity-50 {
    opacity: 0.3;
    background-color: #efefef !important;
}

.round-dot {
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 6px;
}

.weight-box {
    border-radius: 13px;
    border-width: 1px;
    border-style: solid;
    /*border-color: #2C9F93;*/
    padding-left: 10px !important;
    padding-right: 10px !important;
}

.modal-box {
    border-radius: 5px;
}
